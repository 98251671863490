import memeBankText from "../../assets/text/meme-bank-text.svg";
import { LINKS } from "../../utils";

const MemeBankButton = () => {
  return (
    <div className="relative flex justify-center items-center">
      <div className="absolute w-2/5 md:w-1/5 top-20 md:top-0 mt-2 md:mt-6 h-full z-50 flex items-center">
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a
          href={LINKS.MEME_BANK}
          target="_blank"
          rel="noopener noreferrer"
          className="w-full h-full cursor-pointer"
        />
      </div>
      <img
        src={memeBankText}
        alt="MEME BANK"
        draggable="false"
        className="w-full h-auto scale-xxxxxl pt-20 md:scale-normal md:pt-12"
      />
    </div>
  );
};

export default MemeBankButton;

import { useNavigate } from "react-router-dom";

import soldiers from "../assets/soldiers.svg";
import soldiersMobile from "../assets/soldiers-mobile.png";
import disclaimerText from "../assets/text/disclaimer-text.svg";
import camoBackground from "../assets/backgrounds/camo-background-5.svg";

import falconIcon from "../assets/icons/falcon-icon.svg";
import telegramIcon from "../assets/icons/telegram-icon.svg";
import twitterIcon from "../assets/icons/twitter-icon.svg";
import { LINKS } from "../utils";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* CHECK CENTER OF SCREEN */}
      
      {/* <div className="absolute bottom-0 h-dvh w-full border border-yellow flex items-center justify-center">
      <div className="h-1/3 w-1/2 border"/>
      </div> */}

      <div className="w-full relative pt-2 md:pt-44">
        <div className="w-full relative flex items-center justify-center z-10">
          <div className="w-full flex md:hidden">
            <img
              src={soldiersMobile}
              alt="army"
              draggable="false"
              className="w-full h-auto -mb-1"
            />
          </div>
          <div className="w-full hidden md:flex">
            <img
              src={soldiers}
              alt="army"
              draggable="false"
              className="w-full h-auto -mb-8"
            />
          </div>
        </div>
        <div className="relative flex flex-col w-full justify-center items-center py-4 md:py-12 gap-4 md:gap-10">
          <img
            src={camoBackground}
            alt="camo background"
            draggable="false"
            className="w-full h-auto absolute bottom-0 z-0"
          />
          <div className="flex justify-center items-center gap-4 md:gap-7 z-10">
            <a
              href={LINKS.CONTRACT_ADDRESS}
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer"
            >
              <img
                src={falconIcon}
                alt="army"
                draggable="false"
                className="w-5 md:w-10 h-auto"
              />
            </a>

            <a
              href={LINKS.TELEGRAM}
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer"
            >
              <img
                src={telegramIcon}
                alt="army"
                draggable="false"
                className="w-7 md:w-14 h-auto"
              />
            </a>
            <a
              href={LINKS.TWITTER}
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer"
            >
              <img
                src={twitterIcon}
                alt="army"
                draggable="false"
                className="w-6 md:w-12 h-auto"
              />
            </a>
          </div>

          <button
            onClick={() => navigate("/disclaimer")}
            draggable="false"
            className="mb-1 z-10"
          >
            <img
              src={disclaimerText}
              alt="disclaimer copyright 2024"
              draggable="false"
              className="w-full h-auto scale-xxxxl md:scale-normal"
            />
          </button>
        </div>
      </div>
    </>
  );
};

export default Footer;

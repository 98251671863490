import { useState } from "react";

import soldierFrame from "../assets/soldier-frame.svg";
import soldierFrameMobile from "../assets/soldier-frame-mobile.svg";

import svgSoldier1 from "../assets/soldiers/svg/soldier-1.svg";
import svgSoldier2 from "../assets/soldiers/svg/soldier-2.svg";
import svgSoldier3 from "../assets/soldiers/svg/soldier-3.svg";
import svgSoldier4 from "../assets/soldiers/svg/soldier-4.svg";
import svgSoldier5 from "../assets/soldiers/svg/soldier-5.svg";
import svgSoldier6 from "../assets/soldiers/svg/soldier-6.svg";
import svgSoldier7 from "../assets/soldiers/svg/soldier-7.svg";
import svgSoldier8 from "../assets/soldiers/svg/soldier-8.svg";
import svgSoldier9 from "../assets/soldiers/svg/soldier-9.svg";
import svgSoldier10 from "../assets/soldiers/svg/soldier-10.svg";
import svgSoldier11 from "../assets/soldiers/svg/soldier-11.svg";
import svgSoldier12 from "../assets/soldiers/svg/soldier-12.svg";
import svgSoldier13 from "../assets/soldiers/svg/soldier-13.svg";
import svgSoldier14 from "../assets/soldiers/svg/soldier-14.svg";

import pngSoldier1 from "../assets/soldiers/png/soldier-1.png";
import pngSoldier2 from "../assets/soldiers/png/soldier-2.png";
import pngSoldier3 from "../assets/soldiers/png/soldier-3.png";
import pngSoldier4 from "../assets/soldiers/png/soldier-4.png";
import pngSoldier5 from "../assets/soldiers/png/soldier-5.png";
import pngSoldier6 from "../assets/soldiers/png/soldier-6.png";
import pngSoldier7 from "../assets/soldiers/png/soldier-7.png";
import pngSoldier8 from "../assets/soldiers/png/soldier-8.png";
import pngSoldier9 from "../assets/soldiers/png/soldier-9.png";
import pngSoldier10 from "../assets/soldiers/png/soldier-10.png";
import pngSoldier11 from "../assets/soldiers/png/soldier-11.png";
import pngSoldier12 from "../assets/soldiers/png/soldier-12.png";
import pngSoldier13 from "../assets/soldiers/png/soldier-13.png";
import pngSoldier14 from "../assets/soldiers/png/soldier-14.png";

const SVG_SOLDIERS = [
  svgSoldier1,
  svgSoldier2,
  svgSoldier3,
  svgSoldier4,
  svgSoldier5,
  svgSoldier6,
  svgSoldier7,
  svgSoldier8,
  svgSoldier9,
  svgSoldier10,
  svgSoldier11,
  svgSoldier12,
  svgSoldier13,
  svgSoldier14,
];

const PNG_SOLDIERS = [
  pngSoldier1,
  pngSoldier2,
  pngSoldier3,
  pngSoldier4,
  pngSoldier5,
  pngSoldier6,
  pngSoldier7,
  pngSoldier8,
  pngSoldier9,
  pngSoldier10,
  pngSoldier11,
  pngSoldier12,
  pngSoldier13,
  pngSoldier14,
];

const ClickMe = ({ playAudio }: { playAudio: () => void }) => {
  const [selectedSoldier, setSelectedSoldier] = useState(0);

  const handleClick = () => {
    playAudio();
    if (selectedSoldier === 13) {
      setSelectedSoldier(0);
    } else {
      setSelectedSoldier(selectedSoldier + 1);
    }
  };

  return (
    <div className="relative w-full flex justify-center items-center z-20">
      {/* FRAME */}
      <img
        src={soldierFrameMobile}
        alt="click me"
        draggable="false"
        className="w-full h-auto -mt-6 scale-xl flex md:hidden"
      />
      <img
        src={soldierFrame}
        alt="click me"
        draggable="false"
        className="w-full h-auto hidden md:flex"
      />

      {/* SOLDIERS */}
      <button
        onClick={handleClick}
        className="absolute w-1/3 h-full cursor-pointer hidden md:flex z-10"
      />
      <img
        src={SVG_SOLDIERS[selectedSoldier]}
        alt="selected soldier"
        draggable="false"
        className="absolute w-full h-auto hidden md:flex z-0"
      />

      <button
        onClick={handleClick}
        className="absolute w-full h-full cursor-pointer flex md:hidden"
      >
        <img
          src={PNG_SOLDIERS[selectedSoldier]}
          alt="selected soldier"
          draggable="false"
          className="w-full h-auto scale-xl"
        />
      </button>
    </div>
  );
};

export default ClickMe;

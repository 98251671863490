export const BUTTONS = [
    {
      label: "HOME",
      id: "home",
    },
    {
      label: "AIRDROP",
      id: "airdrop",
    },
    {
      label: "SECTION 2",
      id: "section2",
    },
    {
      label: "SECTION 3",
      id: "section3",
    },
  ];

  export enum LINKS {
    BUY_ARMY = "https://jup.ag/swap/SOL-ARMYZt71GXq4vw4mtDs5LnEp4ZgwWKEE2CdMU3WNnFEC",
    MEME_BANK = "https://www.notion.so/ARMY-MEME-BANK-568069abdc2846a98b55424a913ed7b3",
    CONTRACT_ADDRESS = "https://dexscreener.com/solana/ARMYZt71GXq4vw4mtDs5LnEp4ZgwWKEE2CdMU3WNnFEC",
    TELEGRAM = "https://t.me/armyonchain",
    TWITTER = "https://x.com/onchainarmy",
  }
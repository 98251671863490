import { useState } from "react";
import contractAddressText from "../../assets/text/contract-address-text.svg";
import copiedText from "../../assets/text/copied-text.svg";

const ContractAddressButton = () => {
  const [copied, setCopied] = useState<boolean>(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(
      "ARMYZt71GXq4vw4mtDs5LnEp4ZgwWKEE2CdMU3WNnFEC"
    );
    setCopied(true);
  };

  return (
    <>
      <div className="w-full relative flex justify-center items-center">
        <div className="absolute w-full mt-28 px-4 md:w-7/12 h-1/2 md:mt-9 z-50 flex items-center">
          <button
            onClick={handleCopy}
            className="flex h-full grow cursor-copy"
          />
          <button className="w-8 md:w-20 flex flex-none h-full cursor pointer" />
        </div>
        <img
          src={contractAddressText}
          alt="wallet address"
          draggable="false"
          className="w-full h-auto scale-xxxxl pt-16 px-2 md:px-0 md:scale-normal md:pt-10"
        />
      </div>

      {copied && (
        <div className="relative w-full flex justify-center">
          <img
            src={copiedText}
            alt="copied!"
            draggable="false"
            className="absolute top-0 pt-8 md:pt-0 w-full h-auto scale-xxxxl md:scale-normal"
          />
        </div>
      )}
    </>
  );
};

export default ContractAddressButton;

import buyArmyText from "../../assets/text/buy-army-text.svg";
import { LINKS } from "../../utils";

const BuyArmyButton = () => {
  return (
    <div className="relative flex justify-center items-center">
      <div className="absolute w-2/5 mt-32 md:w-1/5 h-full md:mt-10 z-50 flex items-center">
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a href={LINKS.BUY_ARMY} target="_blank" rel="noopener noreferrer" className="w-full h-full cursor-pointer"
        />
      </div>
      <img
        src={buyArmyText}
        alt="Buy $ARMY"
        draggable="false"
        className="w-full h-auto scale-xxxxxl pt-14 md:scale-normal md:pt-10"
      />
    </div>
  );
};

export default BuyArmyButton;

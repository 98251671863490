import camoBackground2 from "../assets/backgrounds/camo-background-2.svg";
import circledXIcon from "../assets/icons/circled-x-icon.svg";

import growBeyondLimitsText from "../assets/text/grow-beyond-limits-text.svg";
import deployTheMemesText from "../assets/text/deploy-the-memes-text.svg";
import howToMakeYourSoldierText from "../assets/text/how-to-make-your-soldier-text.svg";
import guide from "../assets/guide.svg";
import guideMobile from "../assets/guide-mobile.png";
import guideCaptionText from "../assets/text/guide-caption-text.svg";

import BuyArmyButton from "../components/buttons/BuyArmyButton";
import ContractAddressButton from "../components/buttons/ContractAddressButton";
import ClickMe from "../components/ClickMe";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Layout from "../layouts/Layout";
import MemeBankButton from "../components/buttons/MemeBankButton";
import Posters from "../components/Posters";
import { RefObject } from "react";

function Home({ audioRef }: { audioRef: RefObject<HTMLAudioElement> }) {
  // const [loading, setLoading] = useState(true);
  // // transition while loading
  // useEffect(() => {
  //     setTimeout(() => {
  //         setLoading(false);
  //     }, 2000);

  // }, []);

  return (
    <>
      {/* {loading && (
        <div className="fixed top-0 left-0 w-full h-full bg-black flex justify-center items-center z-50">
            <p className="text-white">loading...</p>
        </div>
    )} */}
      <Layout>
        <Header pauseAudio={() => audioRef.current?.pause()} />
        {/* SECTION 1 */}
        <div className="w-full">
          <ClickMe playAudio={() => audioRef.current?.play()} />
          <img
            src={growBeyondLimitsText}
            alt="Grow Beyond Limits"
            draggable="false"
            className="w-full h-auto scale-xxxxxl pt-14 md:scale-normal md:pt-0"
          />
          <BuyArmyButton />
          <ContractAddressButton />
        </div>
        {/* SECTION 2 */}
        <div className="w-full relative mt-28 md:mt-8">
          <div className="w-full relative md:absolute top-0 z-0">
            <img
              src={camoBackground2}
              alt="camo background"
              draggable="false"
              className="w-full h-auto z-0 scale-xl -mb-5 md:mb-0 md:scale-normal"
            />
            <div className="w-full absolute z-30 top-0 md:hidden">
              <img
                src={deployTheMemesText}
                alt="DEPLOY THE MEMES"
                draggable="false"
                className="w-full h-auto z-20 scale-xxxxl pt-6 px-2"
              />
              <img
                src={circledXIcon}
                alt="arrow pointing to x"
                draggable="false"
                className="w-full h-auto scale-xxxxl pt-7 pr-4"
              />
            </div>
          </div>
          <div className="w-full relative">
            <img
              src={deployTheMemesText}
              alt="DEPLOY THE MEMES"
              draggable="false"
              className="w-full h-auto z-20 hidden md:flex md:scale-normal md:pt-24"
            />
          </div>
          <div className="w-full relative">
            <div className="w-full absolute z-30 top-0 hidden md:flex">
              <img
                src={circledXIcon}
                alt="arrow pointing to x"
                draggable="false"
                className="w-full h-auto pr-6"
              />
            </div>
            <Posters />
          </div>
          <MemeBankButton />
        </div>
        {/* SECTION 3 */}
        <div className="relative w-full pt-36 md:pt-32 mb-8 md:mb-10 z-10">
          <img
            src={howToMakeYourSoldierText}
            alt="HOW TO MAKE YOUR SOLDIER"
            draggable="false"
            className="w-full h-auto scale-xxxxl px-2 md:px-0 md:scale-normal mb-14 md:mb-8"
          />
          <img
            src={guide}
            alt="guide"
            draggable="false"
            className="w-full h-auto scale-normal hidden md:flex"
          />
          <img
            src={guideMobile}
            alt="guide"
            draggable="false"
            className="w-full h-auto scale-xxl flex md:hidden"
          />
          <a
              href={"https://x.com/i/lists/1825556187498119480"}
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer border w-full z-10"
            >
          <img
            src={guideCaptionText}
            alt="caption"
            draggable="false"
            className="w-full h-auto scale-xxxxl px-4 md:px-0 md:scale-normal mt-3 md:mt-0"
          />
          </a>

        </div>
        <Footer />
      </Layout>
    </>
  );
}

export default Home;

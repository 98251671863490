import posters from "../assets/posters.svg";
import postersMobile from "../assets/posters-mobile.png";

const Posters = () => {
  return (
    <>
      <img
        src={posters}
        alt="posters"
        draggable="false"
        className="w-full h-auto hidden md:flex"
      />
      <img
        src={postersMobile}
        alt="posters"
        draggable="false"
        className="w-full h-auto flex scale-xxl mt-20 md:hidden"
      />
    </>
  );
};

export default Posters;

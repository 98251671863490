import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Disclaimer from "./Disclaimer";
import { useEffect, useRef } from "react";

const Router = () => {
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    // if song ended, reset
    if (audioRef.current?.ended) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
      return;
    }
  }, [audioRef.current?.ended]);

  return (
    <BrowserRouter>
      <div>
        <audio
          ref={audioRef}
          src={require("../assets/audio/if-you-want-blood.mp3")}
        />
        <Routes>
          <Route path="/" element={<Home audioRef={audioRef} />} />
          <Route
            path="/disclaimer"
            element={<Disclaimer audioRef={audioRef} />}
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default Router;

import Layout from "../layouts/Layout";
import Header from "../components/Header";
import disclaimerFullText from "../assets/text/disclaimer-full-text.svg";
import { RefObject } from "react";

const Disclaimer = ({
  audioRef,
}: {
  audioRef: RefObject<HTMLAudioElement>;
}) => {
  return (
    <Layout>
      <Header pauseAudio={() => audioRef.current?.pause()} />
      <img
        src={disclaimerFullText}
        alt="full disclaimer"
        draggable="false"
        className="w-full h-auto scale-xxl pt-12 pb-12 md:scale-normal md:pt-0 md:pb-48"
      />
    </Layout>
  );
};

export default Disclaimer;

import { ReactNode } from "react";

interface ILayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: ILayoutProps) => (
  <div className="flex flex-col w-full h-dvh justify-center items-center fixed bg-primary">
    <div className="flex flex-col z-20 w-full h-full items-center">
      <div className="flex flex-col items-center h-dvh overflow-x-hidden overflow-y-scroll w-full gap-2 md:gap-12">
        {children}
      </div>
    </div>
  </div>
);

export default Layout;

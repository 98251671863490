import { useNavigate } from "react-router-dom";
import camoBackground from "../assets/backgrounds/camo-background-1.svg";
import header from "../assets/header.svg";
import headerMobile from "../assets/header-mobile.svg";
import { LINKS } from "../utils";

const Header = ({ pauseAudio }: { pauseAudio?: () => void }) => {
  const navigate = useNavigate();

  return (
    <div className="relative w-full z-10">
      <img
        src={camoBackground}
        alt="camo background"
        className="absolute top-0 w-full h-auto"
      />
      {/* DESKTOP BUTTONS */}
      <div
        className="absolute hidden md:flex w-full h-3/5 mt-12 z-50"
        style={{ paddingRight: "3.776%", paddingLeft: "3.776%" }}
      >
        <div className="w-full h-full flex justify-between items-center">
          <div style={{ width: "19%" }} />
          <button
            onClick={() => navigate("/")}
            className="h-full flex cursor-pointer"
            style={{ width: "19%", borderColor: "yellowgreen" }}
          />
          <div
            className="flex h-full"
            style={{ width: "19%", borderColor: "pink" }}
          >
            <button
              onClick={pauseAudio}
              className="flex w-1/3 cursor-pointer"
            />
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a
              href={LINKS.TELEGRAM}
              target="_blank"
              rel="noopener noreferrer"
              className="flex w-1/3 cursor-pointer"
            />
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a
              href={LINKS.TWITTER}
              target="_blank"
              rel="noopener noreferrer"
              className="flex w-1/3 cursor-pointer"
            />
          </div>
        </div>
      </div>
      {/* MOBILE BUTTONS */}
      <div className="absolute flex md:hidden justify-between w-full h-2/5 mt-5 z-50 items-center">
        <button
          onClick={() => navigate("/")}
          className="flex w-1/3 h-full cursor-pointer"
        />
        <div className="flex w-1/3 gap-1 h-full">
          <button onClick={pauseAudio} className="flex grow cursor-pointer" />
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <a
            href={LINKS.TELEGRAM}
            target="_blank"
            rel="noopener noreferrer"
            className="flex grow cursor-pointer"
          />
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <a
            href={LINKS.TWITTER}
            target="_blank"
            rel="noopener noreferrer"
            className="flex grow cursor-pointer"
          />
        </div>
      </div>

      <div className="relative w-full">
        <img
          src={header}
          alt="header"
          draggable="false"
          className="w-full h-auto pt-11 hidden md:flex"
        />
        <img
          src={headerMobile}
          alt="header"
          draggable="false"
          className="w-full h-auto flex md:hidden"
        />
      </div>
    </div>
  );
};

export default Header;
